import * as React from "react"
import Layout from "../components/layout/en"
import PortfolioFicha from "../components/portfolio/"


const PortfolioPage = (props) => {

  return (
    <Layout transparent location={props.location} pageId="portfolioSingle" metaTitle="Nuestros proyectos | Crequs">
      <PortfolioFicha content={ props.pageContext.portfolio} lang="en-UK"/>
    </Layout>
  )
}

export default PortfolioPage
